import { CSSProperties } from 'styled-components';

import {
  CampaignPageFragment,
  ImageFragment,
} from '@hultafors/solidgear/types';

import { HeroImage } from '../HeroImage/HeroImage';

import {
  HeroGeneralContentWrapperStyled,
  HeroGeneralStyled,
  HeroVideoInner,
  HeroVideoStyled,
  VideoBig,
  VideoSmall,
} from './hero-general.styled';

interface HeroGeneralProps {
  imageDesktop?: ImageFragment;
  imageMobile?: ImageFragment;
  imageTablet?: ImageFragment;
  className?: string;
  height?: CSSProperties['height'];
  video?: CampaignPageFragment['video'];
  storyVideo?: CampaignPageFragment['storyVideo'];
  children?: React.ReactNode;
  useOldMobileImage?: boolean;
  oldMobileImage?: ImageFragment;
}

export const HeroGeneral: React.FC<HeroGeneralProps> = ({
  imageDesktop,
  imageTablet,
  imageMobile,
  height,
  className,
  children,
  video,
  storyVideo,
  useOldMobileImage,
  oldMobileImage,
}) => {
  if (!imageDesktop) {
    return null;
  }
  return (
    <HeroGeneralStyled
      $hasStoryVideo={!!video?.url && !!storyVideo?.url}
      $height={height}
      className={className}
    >
      {!video?.url ? (
        <HeroImage
          mobile={useOldMobileImage ? oldMobileImage : imageMobile}
          tablet={imageTablet}
          desktop={imageDesktop}
          priority={true}
        />
      ) : (
        <HeroVideoStyled>
          <HeroVideoInner>
            <VideoBig
              autoPlay
              loop
              playsInline
              muted
              poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              $hideOnMobile={!!storyVideo?.url}
            >
              <source src={video?.url} type="video/mp4" />
            </VideoBig>

            {storyVideo?.url && (
              <VideoSmall
                autoPlay
                loop
                playsInline
                muted
                poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              >
                <source src={storyVideo?.url} type="video/mp4" />
              </VideoSmall>
            )}
          </HeroVideoInner>
        </HeroVideoStyled>
      )}
      <HeroGeneralContentWrapperStyled>
        {children}
      </HeroGeneralContentWrapperStyled>
    </HeroGeneralStyled>
  );
};
