import React, { useEffect, useState } from 'react';

import { useWindowSize } from '@react-hookz/web';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';

import { breakpointsRaw } from '@hultafors/shared/constants';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { HeroBlockFragment } from '@hultafors/solidgear/types';

import { HTag } from '../HTag/HTag';

import styles from './hero-block.module.scss';
import MuxPlayerContent from './mux-player-wrapper';

const ButtonLink = dynamic(() =>
  import('../ButtonLink/ButtonLink').then((mod) => mod.ButtonLink),
);
const HeroImage = dynamic(() =>
  import('../HeroImage/HeroImage').then((mod) => mod.HeroImage),
);

const Paragraph = dynamic(() =>
  import('../paragraph/paragraph').then((mod) => mod.Paragraph),
);

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);

const Grid = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Grid),
);

const GridChild = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.GridChild),
);

interface HeroBlockProps extends HeroBlockFragment {
  priority?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  title,
  topLabel,
  firstCtaLabel,
  secondCtaLabel,
  firstCtaUrl,
  secondCtaUrl,
  imageDesktop,
  imageTablet,
  imageMobile,
  oldMobileImage,
  largePlugImageDesktop,
  largePlugImageTablet,
  largePlugImageMobile,
  largePlug,
  fullWidth,
  priority,
  storyVideo,
  video,
  description,
  alignContentLeft,
  useOldMobileImage,
  textColor,
  thin,
  imageLoad,
}) => {
  const [playbackId, setPlaybackId] = useState<string | undefined>(
    storyVideo?.video?.muxPlaybackId ?? undefined,
  );
  const { settings } = useGlobal();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
    } else {
      setPlaybackId(storyVideo?.video?.muxPlaybackId ?? '');
    }
  }, [width]);

  let typeOfHeading: 'h1' | 'h2' = 'h2';

  if (priority) {
    typeOfHeading = 'h1';
  }

  const heroBlockClassNames = clsx(
    styles['hero-block'],
    imageLoad && styles['hero-block--image'],
    !fullWidth && styles['hero-block--limit-width'],
  );

  const aspectRatioLandscape = thin || largePlug ? 12 / 5 : 16 / 9;
  const aspectRatioPortrait = storyVideo ? 9 / 16 : largePlug ? 3 / 4 : 1 / 1;
  const aspectRatioStyles = {
    '--hero-landscape-aspect-ratio': thin || largePlug ? '12 / 5' : '16 / 9',
    '--hero-portrait-aspect-ratio': storyVideo
      ? '9 / 16'
      : largePlug
      ? '3 / 4'
      : '1 / 1',
  };
  const descriptionStyles = {
    '--description-color': textColor?.hex
      ? textColor.hex
      : 'var(--color-white)',
  };

  // !TODO remove this when content is moved in dato
  const showOldMobileImage =
    !playbackId &&
    useOldMobileImage &&
    oldMobileImage &&
    width < breakpointsRaw.tablet
      ? true
      : false;

  return (
    <div
      data-hero
      className={heroBlockClassNames}
      style={aspectRatioStyles as React.CSSProperties}
    >
      {showOldMobileImage && (
        <HeroImage mobile={oldMobileImage} priority={priority} />
      )}
      {!playbackId && !showOldMobileImage && imageLoad && (
        <ResponsiveImage
          image={imageLoad}
          alt={title}
          aspectRatioLandscape={aspectRatioLandscape}
          aspectRatioPortrait={aspectRatioPortrait}
          priority={priority}
          isBackground
        />
      )}
      {playbackId && (
        <div className={styles['video-wrapper']}>
          <MuxPlayerContent
            video={width && width > breakpointsRaw.tablet ? video : storyVideo}
            aspectRatio={
              width && width > breakpointsRaw.tablet
                ? aspectRatioLandscape
                : aspectRatioPortrait
            }
          />
        </div>
      )}

      <div
        className={clsx(
          styles['hero-content'],
          !largePlug && styles['hero-content--overlay'],
          thin && styles['hero-content--overlay-thin'],
        )}
      >
        <Grid
          className={styles['hero-inner']}
          margin={'48px 24px'}
          rowGap={0}
          columnGap={0}
        >
          <GridChild
            className={clsx(
              styles['inner-content'],
              alignContentLeft && styles['inner-content--left'],
              alignContentLeft && styles['inner-content--end'],
            )}
            columnSpan={[{ columns: 12 }]}
          >
            {topLabel && (
              <Paragraph
                color={textColor?.hex ?? 'white'}
                bold={true}
                hasLeftBorder={true}
              >
                {topLabel}
              </Paragraph>
            )}
            <HTag
              type={typeOfHeading}
              className={styles['title']}
              color={textColor?.hex ? textColor.hex : 'white'}
              styleType={
                width && width > breakpointsRaw.tablet ? 'header1' : 'header3'
              }
              center
            >
              {title}
            </HTag>
            {description && (
              <Markdown
                options={{ forceBlock: true }}
                className={styles['description']}
                style={descriptionStyles as React.CSSProperties}
              >
                {description}
              </Markdown>
            )}
            {(firstCtaLabel || secondCtaLabel) && (
              <div
                className={clsx(
                  styles['button-wrapper'],
                  largePlug && styles['button-wrapper--margin'],
                  !imageLoad && styles['button-wrapper--no-image'],
                )}
              >
                <div className={styles['inner-button-wrapper']}>
                  {firstCtaLabel && firstCtaUrl && (
                    <ButtonLink
                      disabled={false}
                      href={createUrl(firstCtaUrl, settings) || ''}
                      className="White"
                    >
                      {firstCtaLabel}
                    </ButtonLink>
                  )}
                  {secondCtaLabel && secondCtaUrl && (
                    <ButtonLink
                      disabled={false}
                      href={createUrl(secondCtaUrl, settings) || ''}
                      className="White"
                    >
                      {secondCtaLabel}
                    </ButtonLink>
                  )}
                </div>
              </div>
            )}
          </GridChild>
        </Grid>
      </div>
    </div>
  );
};
