import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/solidgear/hooks';

import LogoSvg from '../../svg/solid-gear-logo-text.svg';

import styles from './footer.module.scss';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../footer-old/footer-old').then((mod) => mod.FooterOld),
);

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();
  const datoLogo = footer?.footerLogo?.url;

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }
  // TODO remove svg when using datoLogo
  const Logo = () => (
    <figure className={styles['logo']}>
      {datoLogo ? (
        <Image
          src={datoLogo}
          fill
          alt="Solid Gear Footwear"
          style={{ objectFit: 'contain' }}
        />
      ) : (
        <LogoSvg aria-hidden={true} />
      )}
    </figure>
  );

  return (
    <FooterStyled
      activeMarket={activeMarket}
      bottomIntro={footer?.bottomIntro ?? ''}
      brandLinks={footer?.hultaforsBrands ?? []}
      className={styles['footer']}
      cookieSettingsLabel={footer?.cookieSettings ?? ''}
      introText={footer.introText ?? ''}
      items={footer?.menuItems ?? []}
      logoAriaLabel="Solid Gear Footwear"
      logoIcon={<Logo />}
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={
        footer.selectCountryLabel || global?.changeLanguage || ''
      }
      socialMediaLabel={footer?.socialMediaLabel ?? ''}
      socialMediaMenu={footer?.socialMediaMenu ?? []}
      toggleMarketPicker={toggle}
    />
  );
};
