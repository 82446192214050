import clsx from 'clsx';
import Link from 'next/link';

import styles from './cta-link.module.scss';

interface CtaLinkProps {
  href: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  center?: boolean;
}

export const CtaLink: React.FC<CtaLinkProps> = ({
  href = '',
  className,
  children,
  onClick,
  center,
}) => {
  return (
    <Link
      href={href ?? ''}
      className={clsx(
        className,
        styles['cta-link'],
        center && styles['cta-link__center'],
      )}
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 12L12 8L8 4" stroke="#111111" />
        <path d="M12 8H3" stroke="#111111" />
      </svg>
      {children}
    </Link>
  );
};
