import ArrowRightSvg from '../../svg/arrow-right.svg';
import ExternalLinkSvg from '../../svg/external-link.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';
import { Paragraph } from '../paragraph/paragraph';

import { StoreItemStyled } from './store-item.styled';
export interface StoreItemProps {
  label?: string | null;
  coordinates?: {
    lng?: number | string;
    lat?: number | string;
  };
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  mapLink?: string | null;
  viewOnMap?: string | null;
  emailAddress?: string | null;
  phone?: string | null;
  storeSells?: any[];
  thisStoreAlsoSells?: string | null;
  website?: string | null;
  visitWebSite?: string | null;
  localWeb?: string | null;
  subLabel?: string | null;
  keyPrefix?: string | null;
  id?: string | null;
  $forceCollapsible?: boolean;
}

export const StoreItem: React.FC<StoreItemProps> = ({
  address,
  zipCode,
  city,
  country,
  mapLink,
  viewOnMap,
  emailAddress,
  phone,
  storeSells,
  thisStoreAlsoSells,
  website,
  visitWebSite,
  localWeb,
}) => {
  return (
    <StoreItemStyled>
      <Paragraph className="Address">
        {address && (
          <>
            {address}
            <br />
          </>
        )}
        {zipCode && zipCode} {city && city}
        <br />
        {country && country}
      </Paragraph>
      {viewOnMap && mapLink && (
        <Paragraph className="ViewOnMap">
          <a
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
            className="LeftIconLink"
          >
            <PlaceTagSvg
              aria-hidden={true}
              focusable={false}
              width={16}
              height={16}
            />
            {viewOnMap}
          </a>
        </Paragraph>
      )}

      {emailAddress || phone ? (
        <div className="Contact EmailAndPhoneWrapper">
          {emailAddress && (
            <div className="Email">
              <a href={`mailto:${emailAddress}`} className="LeftIconLink">
                <MailSvg
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
                {emailAddress}
              </a>
            </div>
          )}
          {phone && (
            <div className="Phone">
              <a href={`tel:${phone}`} className="LeftIconLink">
                <PhoneSvg
                  aria-hidden={true}
                  focusable={false}
                  width={16}
                  height={16}
                />
                {phone}
              </a>
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      {storeSells && storeSells.length > 0 && (
        <div className="Contact StoreSells">
          <div className="StoresWrapper">
            <label className="storeText">{thisStoreAlsoSells}</label>
            <div className="Stores">
              {storeSells.map((brand, i) => {
                return (
                  <div key={`Brand-${i}`}>
                    <a
                      href={brand.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brand.name}
                    </a>
                    {i < storeSells.length - 1 && (
                      <div className="Separator">|</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {website && (
        <div className="Contact VisitSite">
          <a href={website} target="_blank" rel="noopener noreferrer">
            {visitWebSite && visitWebSite}
            {visitWebSite && (
              <ExternalLinkSvg
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            )}
            {localWeb && localWeb}
            {localWeb && (
              <ArrowRightSvg
                aria-hidden={true}
                focusable={false}
                width={16}
                height={16}
              />
            )}
          </a>
        </div>
      )}
    </StoreItemStyled>
  );
};
