import styled from 'styled-components';

import { colors, spacing } from '@hultafors/solidgear/helpers';

import { Button } from '../button/button';
import { Paragraph } from '../paragraph/paragraph';
import { Section } from '../section/section';
import { TextButton } from '../text-button/text-button';

export const SafetyHighlightedFilters = styled.div`
  border-bottom: 1px solid ${colors.gray4};
`;

export const SafetySection = styled(Section)`
  margin-top: ${spacing.regular};
  margin-bottom: ${spacing.regular};
`;

export const SafetyTitleParagraph = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: ${spacing.small};
`;

export const SafetyLearnMoreTextButton = styled(TextButton)`
  display: flex;
  padding: 0;
  margin-top: ${spacing.xsmall};
  margin-bottom: ${spacing.regular};
  cursor: pointer;

  &:hover::after {
    transition: all 0.3s ease;
    transform: translateY(3px);
  }
`;

export const SafetyButton = styled(Button)<{ $isFilterSelected?: boolean }>`
  /* Override standard style on hover */
  &:hover {
    background-color: ${colors.white};

    :active {
      border: 1px solid ${colors.gray1};
    }
  }

  background-color: ${colors.white};
  border: 1px solid transparent;
  margin-right: ${spacing.small};
  margin-bottom: ${spacing.small};
  padding: 10px ${spacing.regular} 10px ${spacing.regular};
  padding: 0;
  color: ${colors.solidBlack};
  min-height: 40px;
`;
