import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { fontSizes, Spacing } from '@hultafors/solidgear/helpers';
import { lineHeights } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { colors } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';

export type HTagStyleType =
  | 'header1'
  | 'header1Content'
  | 'header2'
  | 'header2Content'
  | 'header3'
  | 'header3Content'
  | 'header3ContentMedium'
  | 'header4';

interface HTagResponsiveStyleType {
  breakpoint?: Breakpoint;
  styleType: HTagStyleType;
}

export type HTagStyleTypeProps = HTagStyleType | HTagResponsiveStyleType[];

interface HTagProps {
  $center?: boolean;
  $styleType?: HTagStyleTypeProps;
  $verticalMargin?: Spacing;
  $color?: CSSProperties['color'];
  $noMarginTop?: boolean;
}

const fontStyle = (styleType?: HTagStyleType) => {
  if (!styleType) {
    return;
  }
  if (styleType === 'header1Content') {
    return css`
      font-size: ${fontSizes.header1Content};
      line-height: ${lineHeights.header1Content};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegularBold};
    `;
  }
  if (styleType === 'header2') {
    return css`
      font-size: ${fontSizes.header2};
      line-height: ${lineHeights.header2};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontHero};
    `;
  }
  if (styleType === 'header2Content') {
    return css`
      font-size: ${fontSizes.header2Content};
      line-height: ${lineHeights.header2Content};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
    `;
  }
  if (styleType === 'header3') {
    return css`
      font-size: ${fontSizes.header3};
      line-height: ${lineHeights.header3};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontHero};
    `;
  }
  if (styleType === 'header3Content') {
    return css`
      font-size: ${fontSizes.header3Content};
      line-height: ${lineHeights.header3Content};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegular};
    `;
  }
  if (styleType === 'header3ContentMedium') {
    return css`
      font-size: ${fontSizes.header3Content};
      line-height: ${lineHeights.header3Content};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
    `;
  }
  if (styleType === 'header4') {
    return css`
      font-size: ${fontSizes.header4};
      line-height: ${lineHeights.header4};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontHero};
    `;
  }

  return css`
    font-size: ${fontSizes.header1};
    line-height: ${lineHeights.header1};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontHero};
  `;
};

const handleStyleType = ({ $styleType }: HTagProps) => {
  if (Array.isArray($styleType)) {
    return $styleType.map(({ breakpoint, styleType }) => {
      if (breakpoint) {
        return css`
          @media all and (min-width: ${breakpoints[breakpoint]}) {
            ${fontStyle(styleType)}
          }
        `;
      }
      return fontStyle(styleType);
    });
  }
  return fontStyle($styleType);
};

export const StyledHTag = styled.h1<HTagProps>`
  text-decoration: none;
  letter-spacing: 0.01em;
  color: ${({ $color }) => $color ?? colors.black};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  ${handleStyleType};
  ${({ $verticalMargin }) =>
    $verticalMargin &&
    css`
      margin: ${spacing?.[$verticalMargin] ?? 'inherit'} 0;
    `};
  margin-top: ${({ $noMarginTop }) => ($noMarginTop ? 0 : null)};
`;
