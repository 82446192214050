import { useEffect, useState } from 'react';

import { sendGTMEvent } from '@next/third-parties/google';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { mergeUrlSearchParams } from '@hultafors/shared/api';
import {
  ListApiFilter,
  ListApiFilterValue,
  ParsedProductsApiResponse,
} from '@hultafors/shared/types';

import { toggleProductFilters } from '@hultafors/solidgear/api';
import {
  ALL_PRODUCTS_NODE_ID,
  getFilters,
  getFilterTitle,
  getShoeGuideFilterParams,
  initSelection,
  isMultiChoice,
  isSingleChoice,
  PAGE_SIZE_L,
  resolveResult,
  resolveStep,
} from '@hultafors/solidgear/helpers';
import { useGlobal, useShoeGuide } from '@hultafors/solidgear/hooks';
import {
  SelectionValue,
  ShoeGuideSelection,
  SolidgearProduct,
  Step,
} from '@hultafors/solidgear/types';

import { Button } from '../button/button';
import { H1 } from '../H1/H1';
import { SlideIn } from '../slide-in/slide-in';
import { TextButton } from '../text-button/text-button';

import { MultiChoice } from './MultiChoice';
import { Result } from './Result';
import { ShoeGuideStyled } from './ShoeGuide.styled';
import { SingleChoice } from './SingleChoice';

export const ShoeGuide = () => {
  const { asPath } = useRouter();
  const { global, settings } = useGlobal();
  const { isGuideOpen, toggleGuide, shoeGuide } = useShoeGuide();

  const [currentStep, setCurrentStep] = useState<Step>({
    name: 'start',
    number: 0,
  });
  const [selection, setSelection] = useState<ShoeGuideSelection>(initSelection);
  const [perfectMatch, setPerfectMatch] = useState<any[]>([]);
  const [semiPerfectMatch, setSemiPerfectMatch] = useState<any[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [currentFilters, setCurrentFilters] = useState<ListApiFilterValue[]>(
    [],
  );
  const [currentTitle, setCurrentTitle] = useState<string>('');
  const [stepsData, setStepsData] = useState<any[]>([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [content, setContent] = useState<any>(undefined);
  const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
  const [productsUrl, setProductsUrl] = useState<string | null>(null);
  const [hasResult, setHasResult] = useState<boolean>(false);
  const { data, isLoading } = useSWR<
    ParsedProductsApiResponse<SolidgearProduct>
  >(productsUrl, {
    revalidateOnMount: true,
  });

  useEffect(() => {
    if (isGuideOpen) {
      sendGTMEvent({
        action: 'start',
        category: 'ShoeGuideStep',
        event: 'guidestep',
        label: 0,
        value: 0,
      });
    }
  }, [isGuideOpen]);

  useEffect(() => {
    const loadContent = async () => {
      setIsContentLoading(true);

      if (shoeGuide) {
        setContent(shoeGuide);
        setIsContentLoaded(true);
      }
      setIsContentLoading(false);
    };

    if (isGuideOpen && !isContentLoaded) {
      loadContent();
    }
  }, [isGuideOpen]);

  useEffect(() => {
    if (isGuideOpen) {
      toggleGuide();
    }
  }, [asPath]);

  useEffect(() => {
    const filterParams = getShoeGuideFilterParams(selection.filters);
    const params = mergeUrlSearchParams(
      new URLSearchParams({
        includeFilters: 'true',
        pageSize: `${PAGE_SIZE_L}`,
        productCatalogNodeId: `${ALL_PRODUCTS_NODE_ID}`,
      }),
      filterParams,
    );

    setProductsUrl(
      `/api/productslist/${settings.pt?.productList}?${params.toString()}`,
    );
  }, [selection.filters]);

  const selectSingleFilter = (
    name: keyof Omit<ShoeGuideSelection, 'filters' | 'safety'>,
    item: any,
  ) => {
    // Radio selector style
    const filters = toggleProductFilters({
      child: item,
      existingFilters: selection.filters,
      parent: { id: item.parentId, type: 'enumerated' },
    });
    const selectedFilter = selection?.[name]?.id === item.id ? { id: 0 } : item;

    const result: any[] = [];

    filters.forEach((filter) => {
      if (filter.id === item.parentId) {
        if (filter.values.length > 1) {
          filter.values.map((subFilter: any) => {
            if (subFilter.id === item.id) {
              result.push(subFilter);
            }
          });
          filter.subItems = result;
        }
      }
    });

    const currentSelection = {
      ...selection,
      filters,
      [name]: selectedFilter,
    };
    setSelection(currentSelection);
  };

  const selectMultipleFilter = (
    name: keyof Omit<ShoeGuideSelection, 'filters'>,
    item: any,
  ) => {
    const filters = toggleProductFilters({
      child: item,
      existingFilters: selection.filters,
      parent: { id: item.parentId, type: 'enumerated' },
    });

    let selectedFilters: any[] = [];

    const temp = selection[name];

    if (
      Array.isArray(temp) &&
      temp?.find(({ id }: ListApiFilter | SelectionValue) => {
        return id === item.id;
      })
    ) {
      // Remove
      selectedFilters = temp?.filter((x) => x.id !== item.id);
    } else if (Array.isArray(temp)) {
      // Add
      selectedFilters = [...temp, item];
    }

    const result = { ...selection, filters, [name]: selectedFilters };

    setSelection(result);
  };

  useEffect(() => {
    if (currentStep.number > 0 && data && currentStep.number !== 8) {
      const filters = getFilters(data.filters || [], currentStep.name || '');
      const title = getFilterTitle(data.filters || [], currentStep.name || '');

      setCurrentFilters(filters || []);
      setCurrentTitle(title);
      setStepsData([...stepsData, filters || []]);
    } else if (currentStep.number === 8 && data?.items) {
      prepareDataForResult(data.items);
    }
  }, [currentStep]);

  const changeStep = async (isForward: boolean) => {
    const newStepNumber: number = isForward
      ? currentStep.number + 1
      : currentStep.number - 1;

    if (currentStep.number === 0) {
      resetAll();
    }
    const step = resolveStep(newStepNumber);

    setCurrentStep(step);

    if (isForward) {
      sendGTMEvent({
        action: step.name,
        category: 'ShoeGuideStep',
        event: 'guidestep',
        label: step.number,
        value: step.number,
      });
    }
  };

  const prepareDataForResult = (items: SolidgearProduct[]) => {
    const products = [...filteredProducts, { items: items }];
    setFilteredProducts(products || []);
    if (!data) {
      return;
    }
    const matchResult = resolveResult(data, products, PAGE_SIZE_L);

    const matchesArr = matchResult.perfectMatch.concat(
      matchResult.semiPerfectMatch,
    );

    matchesArr.map((match, index) => {
      sendGTMEvent({
        action: match.name,
        category: 'ShoeGuideProductRecommendation',
        event: 'guidestep',
        label: match.sku,
        value: index,
      });
    });
    sendGTMEvent({
      action: matchesArr.length,
      category: 'ShoeGuideRecommendationCount',
      event: 'guidestep',
    });
    setPerfectMatch(matchResult.perfectMatch);
    setSemiPerfectMatch(matchResult.semiPerfectMatch);
    setHasResult(true);
  };

  const restart = () => {
    resetAll();
  };

  const resetAll = () => {
    initSelection.filters = [];
    setCurrentStep({ name: 'start', number: 0 });
    setSelection(initSelection);
    setPerfectMatch([]);
    setSemiPerfectMatch([]);
    setFilteredProducts([]);
    setCurrentFilters([]);
    setCurrentTitle('');
    setStepsData([]);
    setHasResult(false);
  };

  const renderInnerContent = () => {
    return (
      <>
        <div className="BackButtonContainer">
          {currentStep.number >= 1 && !hasResult && (
            <TextButton
              iconLeft={true}
              onClick={() => {
                changeStep(false);
              }}
              iconUrl="/assets/gfx/arrow-left.svg"
            >
              {content.goBack}
            </TextButton>
          )}
        </div>

        {currentStep.number === 0 && (
          <div>
            <H1>{content.title}</H1>
            <Button
              onClick={() => {
                changeStep(true);
              }}
              stretch
              className="NextIcon Black StartButton"
              iconUrl="/assets/gfx/arrow-right-white.svg"
              type="button"
              lowerCase={true}
            >
              {content.startButton}
            </Button>
          </div>
        )}

        {isSingleChoice(currentStep) &&
          !!currentFilters?.length &&
          !hasResult && (
            <SingleChoice
              handleChange={selectSingleFilter}
              selection={selection}
              filters={currentFilters}
              currentStep={currentStep}
              title={currentTitle}
            />
          )}

        {isMultiChoice(currentStep) &&
          !!currentFilters?.length &&
          !hasResult && (
            <MultiChoice
              className="ShoeGuideMultiChoiceContainer"
              handleChange={selectMultipleFilter}
              selection={selection}
              filters={currentFilters}
              currentStep={currentStep}
              title={currentTitle}
            />
          )}

        {hasResult && (
          <Result
            restart={restart}
            rrpLabel={global.rrpLabel || ''}
            rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
            selection={selection}
            perfectMatch={perfectMatch}
            semiPerfectMatch={semiPerfectMatch}
            shoeGuide={content}
          />
        )}
      </>
    );
  };

  if (!content) {
    return null;
  }
  return (
    <ShoeGuideStyled>
      <SlideIn
        toggle={toggleGuide}
        isOpen={isGuideOpen}
        headerText={content.header ?? 'Shoe Guide'}
        closeLabel={global?.close ?? ''}
        fromLeft={false}
        isGuide
      >
        <div className="GuideContainer">{renderInnerContent()}</div>
        {currentStep.number >= 1 && !hasResult && (
          <div className="NextButtonContainer">
            <Button
              onClick={() => {
                changeStep(true);
              }}
              disabled={isLoading}
              className="Black NextButton NextIcon"
              type="button"
              iconUrl="/assets/gfx/arrow-right-white.svg"
              lowerCase={true}
              stretch={undefined}
            >
              {content.next}
            </Button>
          </div>
        )}
      </SlideIn>
    </ShoeGuideStyled>
  );
};
