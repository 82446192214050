import styled, { css } from 'styled-components';

import { spacing } from '@hultafors/solidgear/helpers';
import { colors } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';

import { Paragraph } from '../paragraph/paragraph';
import { TextButton } from '../text-button/text-button';

export const Tabs = styled.div<{ $center?: boolean }>`
  display: flex;
  text-align: center;
  margin-bottom: ${spacing.regular};

  &:empty {
    display: none;
  }
  ${({ $center }) =>
    $center &&
    css`
      justify-content: center;
    `}
`;

interface ActiveProps {
  $active?: boolean;
}

export const Label = styled(Paragraph).attrs({ small: true })<ActiveProps>`
  margin: 0;
  color: ${colors.gray1};

  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.solidBlack};
    `}
`;

export const Tab = styled(TextButton)<ActiveProps>`
  margin-right: ${spacing.regular};
  color: ${colors.gray1};
  margin-bottom: 1px;
  padding: 0;
  padding-block-end: 8px;
  ${({ $active }) =>
    $active &&
    css`
      color: ${colors.solidBlack};
      margin-bottom: 0;
      border-bottom: thin solid black;
    `}
`;
export const ProductInformationTabsStyled = styled.div`
  margin-bottom: ${spacing.regular};

  a:hover {
    cursor: pointer;
  }

  .OverviewText {
    margin: 0;
  }

  .DetailInfo {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      font-size: ${fontSizes.body};
    }
  }
`;
