import { useRouter } from 'next/router';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { PAGE_SIZE_S } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import {
  RelateProductsBlockFragment,
  SolidgearProduct,
} from '@hultafors/solidgear/types';

import { Product } from '../product/product';
import { RelatedProducts } from '../related-products/related-products';
import { Section } from '../section/section';

interface RelatedProductsBlockProps {
  header?: string;
  productCatalogNodeId: string;
}
export const RelatedProductsBlock: React.FC<
  RelatedProductsBlockProps | RelateProductsBlockFragment
> = ({ header, productCatalogNodeId }) => {
  const { global, settings } = useGlobal();

  const { locale } = useRouter();
  const { data } = useSWR<ParsedProductsApiResponse<SolidgearProduct>>(
    productCatalogNodeId &&
      `/api/productslist/${settings.pt?.productList}?${new URLSearchParams({
        includeFilters: 'false',
        pageSize: `${PAGE_SIZE_S}`,
        productCatalogNodeId: `${productCatalogNodeId}`,
        ...(locale && locale !== 'com' ? { locale } : {}),
      }).toString()}`,
    {
      revalidateOnMount: true,
    },
  );

  if (!header && !productCatalogNodeId) {
    return null;
  }

  return (
    data && (
      <Section marginTop className="BorderTop FullWidth MoreMarginBottom">
        <RelatedProducts header={header}>
          {data.items.map((product, i) => (
            <Product
              key={`p-${i}`}
              product={product}
              className="Product"
              rrpLabel={global.rrpLabel || ''}
              rrpIncludingVatLabel={global.rrpIncludingVatLabel || ''}
            />
          ))}
        </RelatedProducts>
      </Section>
    )
  );
};
