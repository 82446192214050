import {
  BulletListFragment,
  CampaignTextBlockFragment,
  ContentBulletListFragment,
  ContentQuoteFragment,
  DotDigitalFormFragment,
  DoubleTextBlockFragment,
  DualImageFragment,
  DualProductBlockFragment,
  FactBoxFragment,
  FaqBlockFragment,
  FindRetailersBlockFragment,
  GenericPlugBlockFragment,
  GridContainerBlockFragment,
  GridFourColBlockFragment,
  GridThreeColBlockFragment,
  GridTwoColBlockFragment,
  HeroBlockFragment,
  ImageBlockFragment,
  ImageSliderFragment,
  LinkBoxBlockFragment,
  MediaWithCaptionFragment,
  OfficesBlockFragment,
  ProductPlugListFragment,
  RelateProductsBlockFragment,
  SafetyClassTableBlockFragment,
  SafetyStandardTableBlockFragment,
  ShoeGuideCtaFragment,
  SimpleTitleAndTextBlockFragment,
  SliderBlockFragment,
  SplitHeroBlockFragment,
  TableBlockFragment,
  TextBlockFragment,
  VideoFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export function isBulletList(item: any): item is BulletListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'BulletListRecord';
}

export function isContentBulletList(
  item: any,
): item is ContentBulletListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentBulletListRecord';
}

export function isCampaignTextBlock(
  item: any,
): item is CampaignTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'CampaignTextBlockRecord';
}

export function isContentQuote(item: any): item is ContentQuoteFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentQuoteRecord';
}

export function isDotDigitalForm(item: any): item is DotDigitalFormFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DotDigitalFormRecord';
}

export function isDoubleTextBlock(item: any): item is DoubleTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DoubleTextBlockRecord';
}

export function isDualImage(item: any): item is DualImageFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DualImageRecord';
}

export function isDualProductBlock(
  item: any,
): item is DualProductBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'DualProductBlockRecord';
}

export function isFactBox(item: any): item is FactBoxFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FactBoxRecord';
}

export function isFaqBlock(item: any): item is FaqBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FaqBlockRecord';
}

export function isFindRetailersBlock(
  item: any,
): item is FindRetailersBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FindRetailersBlockRecord';
}

export function isGenericPlugBlock(
  item: any,
): item is GenericPlugBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GenericPlugBlockRecord';
}

export function isGridContainerBlock(
  item: any,
): item is GridContainerBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridContainerBlockRecord';
}

export function isGridTwocolBlock(item: any): item is GridTwoColBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridTwocolBlockRecord';
}

export function isGridThreecolBlock(
  item: any,
): item is GridThreeColBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridThreecolBlockRecord';
}

export function isGridFourcolBlock(
  item: any,
): item is GridFourColBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'GridFourcolBlockRecord';
}

export function isHeroBlock(item: any): item is HeroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'HeroBlockRecord';
}

export function isImageBlock(item: any): item is ImageBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageRecord';
}

export function isImageSlider(item: any): item is ImageSliderFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ImageSliderRecord';
}

export function isLinkBoxBlock(item: any): item is LinkBoxBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'LinkBoxBlockRecord';
}
export function isMediaWithCaptionBlock(
  item: any,
): item is MediaWithCaptionFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'MediaWithCaptionRecord';
}
export function isOfficesBlock(item: any): item is OfficesBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'OfficesBlockRecord';
}
export function isProductPlugList(item: any): item is ProductPlugListFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductPlugListRecord';
}

export function isRelatedProductsBlock(
  item: any,
): item is RelateProductsBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'RelatedProductsBlockRecord';
}

export function isSafetyClassTableBlock(
  item: any,
): item is SafetyClassTableBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SafetyClassTableBlockRecord';
}

export function isSafetyStandardTableBlock(
  item: any,
): item is SafetyStandardTableBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SafetyStandardTableBlockRecord';
}

export function isShoeGuideCta(item: any): item is ShoeGuideCtaFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ShoeGuideCtaRecord';
}

export function isSimpleTitleAndTextBlock(
  item: any,
): item is SimpleTitleAndTextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SimpleTitleAndTextBlockRecord';
}

export function isSliderBlock(item: any): item is SliderBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SliderBlockRecord';
}

export function isSplitHeroBlock(item: any): item is SplitHeroBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'SplitHeroBlockRecord';
}

export function isTableBlock(item: any): item is TableBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TableBlockRecord';
}

export function isTextBlock(item: any): item is TextBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'TextBlockRecord';
}

export function isVideoBlock(item: any): item is VideoFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VideoRecord';
}

export function isVisibleSeoBlock(item: any): item is VisibleSeoBlockFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'VisibleSeoBlockRecord';
}
