import styled, { css } from 'styled-components';

import { colors } from '@hultafors/solidgear/helpers';
import { fontSizes } from '@hultafors/solidgear/helpers';
import { fontWeights } from '@hultafors/solidgear/helpers';
import { fontFamilies } from '@hultafors/solidgear/helpers';
import { spacing } from '@hultafors/solidgear/helpers';

interface BadgeStyledProps {
  $inline?: boolean;
  $round?: boolean;
  $red?: boolean;
  $small?: boolean;
  $tiny?: boolean;
  $new?: boolean;
  $premium?: boolean;
}

export const BadgeStyled = styled.div<BadgeStyledProps>`
  position: ${({ $inline }) => ($inline ? 'relative' : 'absolute')};
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  font-size: ${fontSizes.bodyXS};
  letter-spacing: 0.1rem;
  text-align: center;
  line-height: 16px;
  width: auto;
  height: 16px;
  text-transform: uppercase;
  margin-left: ${spacing.xsmall};
  margin-bottom: ${({ $inline }) => ($inline ? '12px' : '0')};

  ${({ $round, $red }) =>
    $round &&
    css`
      margin: 0;
      padding: 0;
      border-radius: 27px;
      background-color: ${$red ? colors.solidOrange : colors.white};
      border: 1px solid;
      border-color: ${$red ? colors.solidOrange : colors.black};
      font-weight: ${fontWeights.fontMedium};
      color: ${colors.black};
      line-height: 40px;

      &::before {
        display: none;
      }
    `}

  ${({ $small }) =>
    $small &&
    css`
      width: 32px;
      height: 32px;
      line-height: 32px;
    `}

  ${({ $tiny }) =>
    $tiny &&
    css`
      width: 16px;
      height: 16px;
      line-height: 14px;
      letter-spacing: 0;
    `}

  ${({ $new }) =>
    $new &&
    css`
      display: flex;
      align-items: center;
      padding: 0 5px;
      letter-spacing: 0.05rem;
      font-size: ${fontSizes.bodyS};
      font-family: ${fontFamilies.fontRegularBold};
      text-transform: none;
      color: ${colors.solidOrange};
      top: ${spacing.xsmall};
    `}

  ${({ $premium }) =>
    $premium &&
    css`
      display: flex;
      align-items: center;
      background: ${colors.black};
      color: ${colors.white};
      padding: 0 5px;
      letter-spacing: 0.05rem;
    `}
`;
