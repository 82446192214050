import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { TextBlockFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { CtaLink } from '../cta-link/cta-link';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { HTag } from '../HTag/HTag';

import styles from './text-block.module.scss';

interface TextBlockProps extends TextBlockFragment {
  ctaLabelIcon?: boolean;
  fullWidth?: boolean;
  centerText?: boolean;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  header,
  body,
  ctaLabel,
  ctaLink,
  smallerText,
  ctaLabelIcon,
  fullWidth,
  centerText,
}) => {
  const { settings } = useGlobal();

  return (
    <div
      className={clsx(
        styles['text-block'],
        smallerText && styles['text-block__smaller-text'],
      )}
    >
      <Grid
        className={styles['grid']}
        maxWidth={'var(--grid-max-width, 1440px)'}
      >
        <GridChild
          columnSpan={[
            { columns: 5 },
            {
              breakpoint: 'mobileMax',
              columns: fullWidth ? 12 : 10,
              start: fullWidth ? 0 : 2,
            },
            {
              breakpoint: 'desktop',
              columns: fullWidth ? 12 : 6,
              start: fullWidth ? 0 : 4,
            },
          ]}
        >
          {header && (
            <HTag
              center={centerText}
              type="h2"
              verticalMargin="regular"
              noMarginTop
              styleType={[
                { styleType: 'header4' },
                { breakpoint: 'mobileMax', styleType: 'header4' },
              ]}
              className={styles['h-tag']}
            >
              {header}
            </HTag>
          )}
          <Markdown
            options={{ forceBlock: true }}
            className={clsx(
              styles['markdown'],
              centerText && styles['markdown__center'],
            )}
          >
            {body ?? ''}
          </Markdown>
          {ctaLink && ctaLabel && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {ctaLabelIcon ? (
                <CtaLink
                  href={createUrl(ctaLink, settings) || ''}
                  center={centerText}
                >
                  {ctaLabel}
                </CtaLink>
              ) : (
                <ButtonLink
                  href={createUrl(ctaLink, settings) || ''}
                  gray
                  className={clsx(ctaLabelIcon && styles['cta-label-icon'])}
                >
                  {ctaLabel}
                </ButtonLink>
              )}
            </>
          )}
        </GridChild>
      </Grid>
    </div>
  );
};
