import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

const PlainButton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.PlainButton),
);

const NavItem = dynamic(() =>
  import('../nav-item/nav-item').then((module) => module.NavItem),
);

export const NavStyled = styled.nav`
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  z-index: 10;
  height: var(--header-height);
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${colors.white};
  border-bottom: thin solid var(--color-border);
  padding-block: ${spacing.xsmall};
  padding-inline: ${spacing.xsmall};

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: ${spacing.pagePadding};
    grid-template-columns: 160px 1fr 160px;
  }
`;
export const Start = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.xsmall};
  height: 100%;
  z-index: 1;
`;
export const Middle = styled.div`
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 1;
  height: 100%;
  gap: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
  }

  > button {
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    font-family: ${fontFamilies.fontRegular};
    cursor: pointer;
  }

  > * {
    position: relative;
    display: inline-flex;
    block-size: 100%;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      inline-size: 100%;
      inset-block-end: -9px;
      border-block-end: thin solid ${colors.solidBlackHover};
      inset-inline-start: 0;
      opacity: 0;
      transition: opacity 0.1s linear;

      /* pointer-events: none; */
    }

    &.active::after,
    &:hover::after {
      opacity: 1;
    }

    button {
      padding: 0;
      font-family: ${fontFamilies.fontRegular};
    }

    a,
    button,
    .link {
      font-size: ${fontSizes.body};
      font-style: normal;
      font-weight: normal;
      color: ${colors.solidBlack};
      line-height: ${lineHeights.body};
      text-decoration: none;
      display: block;
      block-size: auto;
      transition: color 0.1s linear;

      &:hover {
        color: ${colors.solidBlackHover};
      }
    }

    &.active a {
      color: ${colors.warning};
    }
  }
`;
export const End = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.xsmall};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.desktop}) {
    gap: ${spacing.small};
  }
`;

export const FavoriteToggle = styled(NavItem)`
  min-width: var(--min-tap-size);
  min-height: var(--min-tap-size);

  @media screen and (min-width: ${breakpoints.desktop}) {
    min-inline-size: inherit;
    min-block-size: inherit;
  }
`;

export const MenuToggler = styled(PlainButton)`
  width: var(--min-tap-size);
  height: var(--min-tap-size);
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  position: relative;
  display: flex;
  width: 157px;
  align-items: center;
  color: ${colors.solidBlack};
  height: 24px;

  svg {
    margin-block-start: -4px;
  }
`;

interface DesktopProps {
  $desktop?: boolean;
}

const desktopStyle = css<DesktopProps>`
  ${({ $desktop }) =>
    $desktop &&
    css`
      display: none;

      @media screen and (min-width: ${breakpoints.desktop}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    `};
`;

export const NavToggle = styled.button<DesktopProps>`
  ${buttonReset};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colors.solidBlack};
  min-width: var(--min-tap-size);
  min-height: var(--min-tap-size);

  @media screen and (min-width: ${breakpoints.desktop}) {
    min-inline-size: inherit;
    min-block-size: inherit;
  }

  &:hover {
    color: ${colors.solidBlackHover};
  }

  ${desktopStyle};
  svg {
    block-size: 24px;
  }
`;

export const NavToggleLabel = styled.span`
  text-align: center;
  white-space: nowrap;
  font-size: 10px;
  letter-spacing: 0.5px;
  margin-block-start: 0.25rem;
`;

export const PartnerPortalLink = styled(Link)<DesktopProps>`
  line-height: 1;
  color: ${colors.solidBlack};

  &:hover {
    color: ${colors.solidBlackHover};
  }

  text-decoration: none;
  ${desktopStyle};
`;
