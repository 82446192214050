import { useGlobal } from '@hultafors/solidgear/hooks';

import ClearSvg from './clear.svg';
import {
  ClearButton,
  SearchButton,
  SearchField,
  SearchFieldForm,
  SearchFieldWrapper,
} from './search-input.styled';
import SearchSvg from './search.svg';

interface SearchInputProps {
  onSubmit: React.FormEventHandler;
  onChange: React.ChangeEventHandler;
  onClear(): void;
  value: string;
  name?: string;
  placeholder?: string;
  className?: string;
  tall?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  onChange,
  onClear,
  name = 'q',
  placeholder,
  value,
  tall,
  className,
}) => {
  const { global } = useGlobal();
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      onClear();
    }
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };
  return (
    <SearchFieldWrapper $tall={tall} className={className}>
      <SearchFieldForm
        onSubmit={onSubmit}
        autoComplete="off"
        aria-label={global?.searchLabel || ''}
      >
        <SearchField
          type="text"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          name={name}
          onKeyDown={handleKeyDown}
          aria-label={global?.searchLabel || ''}
        />
        {value && (
          <ClearButton onClick={onClear} aria-label={global?.clear || ''}>
            <ClearSvg
              focusable="false"
              aria-hidden="true"
              width={18}
              height={18}
            />
          </ClearButton>
        )}

        <SearchButton
          disabled={!value.length}
          type="submit"
          aria-label={global?.searchLabel || ''}
        >
          <SearchSvg
            focusable="false"
            aria-hidden="true"
            width={18}
            height={18}
          />
        </SearchButton>
      </SearchFieldForm>
    </SearchFieldWrapper>
  );
};
