import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { createUrl } from '@hultafors/solidgear/helpers';
import { useGlobal } from '@hultafors/solidgear/hooks';
import { ImageFragment } from '@hultafors/solidgear/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { DualProductPlugStyled } from './dual-product-plug.styled';

interface DualProductPlugProps {
  title?: string;
  label?: string;
  ctaTitle?: string;
  ctaUrl?: string;
  imageOne?: ImageFragment | null;
  imageTwo?: ImageFragment | null;
  sizesDesktop?: string;
}

export const DualProductPlug: React.FC<DualProductPlugProps> = ({
  imageOne,
  imageTwo,
  title = '',
  label = '',
  ctaTitle = '',
  ctaUrl = '',
  sizesDesktop = `(min-width: ${breakpoints.desktop}) 50vw, 100vw`,
}) => {
  const { settings } = useGlobal();
  if (!imageOne || !imageTwo) {
    return null;
  }
  return (
    <DualProductPlugStyled>
      <Grid className="Grid">
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 6, start: 4 },
            { breakpoint: 'desktop', columns: 4, start: 5 },
          ]}
        >
          <div className="ContentWrapper">
            {label && <p className="Label">{label}</p>}
            <span className="dash" />
            {title && <h2>{title}</h2>}
            <ButtonLink
              href={createUrl(ctaUrl, settings) || ''}
              className="White"
            >
              {ctaTitle}
            </ButtonLink>
          </div>
        </GridChild>
      </Grid>
      <div className="ImageWrapper">
        <Grid columns={2} columnGap={0} maxWidth="none" className="ImageGrid">
          <GridChild
            columnSpan={[
              { columns: 2 },
              { breakpoint: 'desktop', columns: 1, start: 1 },
            ]}
          >
            <Image
              src={imageOne.responsiveImage?.src || ''}
              alt={imageOne.alt || ''}
              fill
              sizes={sizesDesktop}
              placeholder="blur"
              blurDataURL={imageOne.responsiveImage?.base64 || undefined}
            />
          </GridChild>
          <GridChild
            columnSpan={[
              { columns: 0 },
              { breakpoint: 'desktop', columns: 1, start: 2 },
            ]}
          >
            {imageTwo && (
              <span className="ImageWrapper">
                <Image
                  src={imageTwo.responsiveImage?.src || ''}
                  alt={imageTwo.alt || ''}
                  fill
                  sizes={sizesDesktop}
                  placeholder="blur"
                  blurDataURL={imageTwo.responsiveImage?.base64 || undefined}
                />
              </span>
            )}
          </GridChild>
        </Grid>
      </div>
    </DualProductPlugStyled>
  );
};
