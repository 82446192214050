import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

export const DualProductPlugStyled = styled.div`
  /* height: 600px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.solidBlack};
  aspect-ratio: 1 / 1;

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 2 / 1;
  }

  .Grid {
    z-index: 1;
  }

  .ContentWrapper {
    padding: 0 ${spacing.regular};
    padding-block-end: ${spacing.regular};
    display: flex;
    flex-direction: column;
    align-items: center;

    .dash {
      inline-size: 24px;
      border: 1px solid ${colors.white};
      margin: ${spacing.xsmall} 0;
    }

    .Label {
      padding: 0;
      margin: 0;
      font-family: ${fontFamilies.fontRegular};
      font-style: normal;
      font-weight: normal;
      font-size: ${fontSizes.header3Content};
      text-align: initial;
      color: ${colors.white};
    }

    h2 {
      margin: 0;
      padding: 0;
      margin-block-end: ${spacing.regular};
      font-family: ${fontFamilies.fontHero};
      font-style: normal;
      font-weight: normal;
      font-size: ${fontSizes.header2};
      line-height: ${lineHeights.header2};
      color: ${colors.white};
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-shadow: 0 4px 30px rgb(0 0 0 / 25%);

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header1};
        line-height: ${lineHeights.header1};
      }
    }

    a,
    .link {
      overflow: hidden;
      white-space: nowrap;
      max-inline-size: 100%;
    }
  }

  .ImageWrapper {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 100%;
    block-size: 100%;
    aspect-ratio: 1 / 1;
  }

  .ImageGrid {
    block-size: 100%;
  }
`;
