import {
  FavoriteStyled,
  Inner,
  StyledRemoveFavoriteSvg,
  StyledStarSvg,
} from './favorite.styled';

interface FavoriteProps {
  toggleFavorite: (...args: any[]) => any;
  product: object;
  inFavorites?: boolean;
  isFavorite: boolean;
  bigStar?: boolean;
  className?: string;
  clearLabel?: string;
  addToFavoritesLabel?: string;
}

export const Favorite: React.FC<FavoriteProps> = ({
  className,
  toggleFavorite,
  product,
  inFavorites,
  isFavorite,
  bigStar,
  clearLabel,
  addToFavoritesLabel,
}) => {
  let icon: React.ReactNode = null;
  let size = 16;
  if (bigStar) {
    size = 24;
  }
  if (!inFavorites) {
    icon = (
      <StyledStarSvg
        aria-hidden={true}
        focusable={false}
        width={size}
        height={size}
        className="star"
      />
    );
  }
  if (inFavorites) {
    icon = (
      <StyledRemoveFavoriteSvg
        aria-hidden={true}
        focusable={false}
        width={size}
        height={size}
        className="remove"
      />
    );
  }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    toggleFavorite(product, isFavorite);
  };
  return (
    <FavoriteStyled className={className}>
      <Inner
        $active={isFavorite}
        onClick={onClick}
        aria-label={isFavorite ? clearLabel : addToFavoritesLabel}
      >
        {icon}
      </Inner>
    </FavoriteStyled>
  );
};
