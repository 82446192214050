import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';
const PlainButton = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.PlainButton),
);

const desktopMenuHeight = 300;

interface SlideInStyledProps {
  $fromTop?: boolean;
  $fromLeft?: boolean;
  $small?: boolean;
  $isSearch?: boolean;
  $isFilter?: boolean;
  $padContent?: boolean;
}

export const SlideInStyled = styled.div<SlideInStyledProps>`
  .SlideInBackground {
    inline-size: 100%;
    block-size: 100%;
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: -1;
    overflow-x: hidden;
    background-color: ${colors.overlayTransparent};
    opacity: 0;
    transition: opacity 0.3s, z-index 0.1s 0.3s;

    &.active {
      z-index: ${({ $fromTop }) => ($fromTop ? '9' : '10')};
      opacity: 1;
      transition: opacity 0.3s, z-index 0.1s;
    }
  }

  .SlideInInside {
    position: absolute;
    inset-block-start: 0;
    inset-inline: ${({ $fromLeft }) => ($fromLeft ? 0 : 'auto')}
      ${({ $fromLeft }) => ($fromLeft ? 'auto' : 0)};
    display: flex;
    flex-direction: column;
    inline-size: 100%;
    block-size: 100%;
    z-index: 12;
    box-sizing: border-box;
    background-color: ${colors.white};
    transform: ${({ $fromTop, $fromLeft }) =>
      $fromTop
        ? 'translateY(-110%)'
        : $fromLeft
        ? 'translateX(-110%)'
        : 'translateX(110%)'};
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: ${({ $small, $isSearch, $fromTop }) =>
        $small && !$isSearch
          ? 'translateY(-61%)'
          : $fromTop
          ? 'translateY(0%)'
          : 'translateX(0%)'};
      transition: transform 0.3s ease-in-out;
    }

    @media screen and (min-width: ${breakpoints.bigMobile}) {
      inline-size: 100%;
      max-inline-size: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
      max-block-size: ${({ $fromTop }) =>
        $fromTop ? desktopMenuHeight + 'px' : 'none'};
      ${({ $isSearch }) =>
        $isSearch &&
        css`
          margin-top: var(--header-height);
          max-height: 130px;
        `};
    }

    ${({ $isFilter, $fromTop }) =>
      $isFilter &&
      css`
        @media screen and (min-width: ${breakpoints.bigMobile}) {
          max-inline-size: ${$fromTop ? 'none' : '477px'};
        }
      `}

    .SlideInHeader {
      display: ${({ $isSearch }) => ($isSearch ? 'none' : 'flex')};
      justify-content: space-between;
      align-items: center;
      block-size: var(--header-height);
      min-block-size: var(--header-height);
      padding: 0
        ${({ $isFilter }) => ($isFilter ? spacing.regular : spacing.small)};
      border-block-end: ${({ $isFilter }) =>
        $isFilter ? 'none' : `1px solid ${colors.gray4}`};

      .HeaderText {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: ${fontSizes.header3Content};
          line-height: ${lineHeights.header3Content};
          color: ${colors.solidBlack};
        }

        button {
          overflow: visible;
          text-transform: none;
          border: none;
          appearance: none;
          display: flex;
          align-items: center;
          outline: none;
          background: none;
          padding-inline-start: 0;
        }

        .HeaderIcon {
          inline-size: ${spacing.regular};
          block-size: ${spacing.regular};
          display: inline-flex;
          margin-inline-end: ${spacing.xsmall};

          @media screen and (min-width: ${breakpoints.mediumMobile}) {
            margin-inline-end: ${spacing.small};
          }
        }

        .Extras {
          margin-inline-start: ${spacing.xsmall};
          color: ${colors.solidOrange};
        }
      }

      .CloseBtn {
        display: flex;
        inline-size: ${spacing.regular};
        block-size: 100%;
        cursor: pointer;

        svg {
          inline-size: ${spacing.regular};
          block-size: ${spacing.regular};
        }

        svg path {
          transition: 0.2s ease;
        }

        &:hover {
          svg path {
            fill: ${colors.solidBlackHover};
          }
        }
      }

      .SlideInExit {
        display: flex;
        align-items: center;

        .Clear {
          display: flex;
          align-items: center;
          margin-inline-end: ${spacing.small};
          font-size: ${fontSizes.body};
          line-height: ${lineHeights.bodyXS};
          cursor: pointer;
          transition: 0.2s ease;

          svg {
            inline-size: ${spacing.small};
            block-size: ${spacing.small};
            margin-inline-start: ${spacing.xsmall};

            path {
              transition: 0.2s ease;
            }
          }

          &:hover {
            color: ${colors.solidBlackHover};

            svg path {
              fill: ${colors.solidBlackHover};
            }
          }
        }

        span {
          font-size: ${fontSizes.small};
          letter-spacing: 0.5px; /* 0.5px */
        }
      }
    }

    .SlideInBody {
      padding: ${({ $padContent }) =>
        $padContent
          ? `${spacing.regular} ${spacing.pagePaddingMobile} ${spacing.medium} ${spacing.pagePaddingMobile}`
          : ''};
      overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'scroll')};
      block-size: ${({ $fromTop, $isSearch }) =>
        $fromTop && !$isSearch ? 'auto' : '100%'};
      inline-size: 100%;
      box-sizing: border-box;

      &.isCart {
        padding: 0 ${spacing.small} 150px ${spacing.small};
      }
    }
  }
`;

export const CloseButton = styled(PlainButton)`
  cursor: pointer;
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
`;
