import { FC } from 'react';

import { isMorePages } from '@hultafors/shared/helpers';
import { ListApiPaging } from '@hultafors/shared/types';

import { Button } from '../button/button';
import { Loader } from '../loader/loader';
import { TextButton } from '../text-button/text-button';

import { PagerStyled } from './pager.styled';

export interface PagerProps {
  showMoreText: string;
  ofText: string;
  productsText: string;
  loadMore: React.MouseEventHandler;
  pagerData?: ListApiPaging;
  loading?: boolean;
  showAll: React.MouseEventHandler;
  showAllText: string;
}

export const Pager: FC<PagerProps> = ({
  showMoreText,
  pagerData,
  loadMore,
  showAll,
  showAllText,
  ofText,
  productsText,
  loading,
}) => {
  if (!pagerData) {
    return null;
  }
  const { itemCount, pageNumber, pageCount, pageSize } = pagerData;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }
  const hasMorePages = isMorePages(pagerData);

  const resultCount =
    pageNumber * pageSize > itemCount ? itemCount : pageNumber * pageSize;

  return (
    <PagerStyled>
      <div className="PagerInner">
        <div className="Pager">
          {resultCount} {ofText} {itemCount} {productsText}
        </div>
        {hasMorePages && (
          <div className="Buttons">
            <Button
              onClick={loadMore}
              center
              className="Outline"
              stretch={undefined}
            >
              {showMoreText}
            </Button>
            <TextButton className="TextButton" onClick={showAll}>
              {showAllText}
            </TextButton>
            {loading && <Loader />}
          </div>
        )}
      </div>
    </PagerStyled>
  );
};
