import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

interface ButtonLinkStyledProps {
  $disabled?: boolean;
  $gray?: boolean;
}

export const ButtonLinkStyled = styled(Link)<ButtonLinkStyledProps>`
  appearance: none;
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${spacing.medium};
  padding: 0 ${spacing.regular} 0 ${spacing.regular};
  box-sizing: border-box;
  margin-top: ${spacing.regular};
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  font-weight: normal;
  color: ${colors.white};
  border-radius: 1px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;

  &:hover {
    background-color: ${colors.gray5};
    border-color: ${colors.gray5};
  }

  &.Red {
    border: 1px solid ${colors.warning};
    background-color: ${colors.warning};
  }

  &.Black {
    border: 1px solid ${colors.black};
    background-color: ${colors.black};
  }

  &.Outline {
    background-color: transparent;
    border: 1px solid ${colors.black};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.black};
      color: ${colors.white};
    }
  }

  &.OutlineWhite {
    background-color: transparent;
    border: 1px solid ${colors.white};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  &.White {
    background-color: ${colors.gray5};
    border: 1px solid ${colors.gray4};
    color: ${colors.solidBlack};

    &:hover {
      background-color: ${colors.gray4};
      border: 1px solid ${colors.gray3};
    }
  }

  ${({ $gray }) =>
    $gray &&
    css`
      background-color: ${$gray ? colors.gray5 : null};
      border: ${$gray ? `1px solid ${colors.gray4}` : null};
      color: ${colors.solidBlack};

      &:hover {
        background-color: ${$gray ? colors.gray4 : null};
        border: ${$gray ? `1px solid ${colors.gray3}` : null};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      border: 0;
      color: ${colors.gray1};
      background-color: ${colors.warning};
      transition: 0.2s;
      opacity: 0.5;

      svg {
        opacity: 0.3;
      }
    `}
`;
